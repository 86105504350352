import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import adisa from '../../assets/images/departments/oral-path/adisa.png';
import awak from '../../assets/images/departments/oral-path/awak.png';
import effiom from '../../assets/images/departments/oral-path/effiom.png';
import ofili from '../../assets/images/departments/oral-path/ofili.png';
import oluseyi from '../../assets/images/departments/oral-path/oluseyi.png';

const OralPath = () => {
  const services = [
    {
      name:
        'Provision of oral biopsy services (histopathologic, immunohistopathologic special staining techniques) for Lagos University Teaching Hospital patients',
    },
    {
      name:
        'Training of resident doctors in the specialty of Oral and Maxillofacial Pathology',
    },
    {
      name: 'Medical research by clinical staff and resident doctors',
    },
    {
      name:
        'Retraining of nonacademic staff on basic  administrative procedures',
    },
  ];

  return (
    <Layout pageTitle='Department of Oral And Maxillofacial Pathology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Oral And Maxillofacial Pathology' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Oral And Maxillofacial Pathology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The present department of Oral Biology and Oral
                        Pathology started as a unit of Oral Pathology in the
                        department of Oral Surgery/Oral Pathology in 1968 and
                        had its 1st pioneer professor in June 1969, that is,
                        Prof. Roger Browne, a visiting professor from University
                        of Birmingham. <br />
                        <br />
                        The department of Oral & Maxillofacial Pathology/Biology
                        was created by the council of University of Lagos in
                        1981 with the following members of staff; (a) clinical /
                        academic: Prof H. A. Mosadomi, Dr. D. R. Sawyer,
                        Associate Professor, Dr. O. Odukoya, Research Fellow II
                        and (b) Laboratory Staff; Mr. F.A. Fasan, Chief
                        Technologist, Mr. Mayor, Assistant Chief Technologist,
                        Mr. Ibok and Mr. F. Adeshan, both Senior Laboratory
                        Assistants. <br />
                        <br />
                        The department of Oral and Maxillofacial
                        Pathology/Biology is located in the Faculty of Dental
                        Sciences building in Lagos University Teaching Hospital
                        premise Idi-Araba. <br />
                        <br />
                        The department is involved in training residents in oral
                        pathology, which is the basis of understanding disease
                        processes upon which clinical dentistry practice is
                        based and also in oral biology which is the Basic
                        Sciences which prepares students for understanding of
                        clinical dentistry and providing oral biopsy services,
                        in form of histopathologic interpretations of lesions
                        for patients in Lagos university teaching hospital and
                        Nigeria.
                        <br />
                        <br />
                        The clinical staff and residents also undertake various
                        research projects geared towards understanding genesis
                        of a number of diseases encountered in clinical practice
                        with the objective of applying appropriate treatment,
                        and ultimately preventing these diseases. <br />
                        <br /> The department has successfully trained more than
                        15 Resident Doctors, who have obtained Fellowship
                        Certificates from the National Postgraduate Medical
                        College of Nigeria and West African College of Surgeons.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oluseyi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Oluseyi Folake Ajayi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Associate Professor
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, FWACS,MPH (Lagos)                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={effiom} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Olajumoke Ajibola Effiom</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Associate Professor
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, Postgraduate Diploma In Hospital
                            MGT.                            
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. J. N. Onyejiaka</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            ADMLS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss. F. B. Kareem</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Medical Lab Technician
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adisa} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss Anu Rebecca Adisa</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Health Assistant
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={awak} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr Awak Timothy Crispin</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Principal Executive Officer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            National Diploma In Bus. Admin.                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ofili} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss Janet Isioma Ofili</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Chief Data Processing Officer                           
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:omfpath@luth.gov.ng'>                        
                        omfpath@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default OralPath;
